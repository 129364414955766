import { Component, OnInit } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/images/tech/001.svg"
    },
    {
      image: "assets/images/tech/002.svg"
    },
    {
      image: "assets/images/tech/003.svg"
    },
    {
      image: "assets/images/tech/004.svg"
    },
    {
      image: "assets/images/tech/005.svg"
    },
    {
      image: "assets/images/tech/006.svg"
    }
  ];

   /**
   * Clients Logo
   */
    clients_logo_2: client[] = [
      {
        image: "assets/images/tech/007.svg"
      },
      {
        image: "assets/images/tech/008.svg"
      },
      {
        image: "assets/images/tech/009.svg"
      },
      {
        image: "assets/images/tech/010.svg"
      },
      {
        image: "assets/images/tech/011.svg"
      },
      {
        image: "assets/images/tech/012.svg"
      }
      
    ];

  constructor() { }

  ngOnInit(): void {
  }

}
