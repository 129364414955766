import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface member {
  profile: string,
  list: string[],
  name: string,
  designation: string,
};

@Component({
  selector: 'app-page-aboutus',
  templateUrl: './page-aboutus.component.html',
  styleUrls: ['./page-aboutus.component.css']
})

/**
 * Aboutus Component
 */
export class PageAboutusComponent implements OnInit {

  /**
   * Member Data
   */
  memberData: member[] = [
    {
      profile: "assets/images/client/00.png",
      list: ['facebook', "instagram", "twitter", "linkedin"],
      name: "Kent",
      designation: "Director, Head Global Operations"
    },
    {
      profile: "assets/images/client/00.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Shen",
      designation: "Director, Head Malaysia Operations"
    },
    {
      profile: "assets/images/client/00.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Gopi",
      designation: "Head, India Operations"
    },
    {
      profile: "assets/images/client/00.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Das",
      designation: "Head, Technology"
    }
  ];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
