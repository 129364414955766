<section class="bg-half-170 d-table w-100">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading me-lg-4">
          <h1 class="heading mb-3">Creative Ideas and Solutions For Your <span class="text-primary">Success</span> </h1>
          <p class="para-desc text-muted">Together with Aventuri let's Ideate, Design and Innovate to Create Next
            Generation Connected Digital Experiences.</p>
          <div class="mt-4">
            <a routerLink="/page-contact-one" class="btn btn-primary mt-2 me-2"><i class="uil uil-envelope"></i> Get
              Started</a>
            <a routerLink="/page-services" class="btn btn-outline-primary mt-2"><i class="uil uil-book-alt"></i>
              Services</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/illustrator/Startup_SVG.svg" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- How It Work Start -->
<section class="section bg-light border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Work With Industry First Design and Technology Experts</h4>
          <p class="text-muted para-desc mb-0 mx-auto">With <span class="text-primary fw-bold">Aventuri's</span>
            access to global talent pool accross Asia, We cater all your technology needs by bridging you
            with the best in class Industry Experts</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img src="assets/images/illustrator/SEO_SVG.svg" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Change the way you build your Business</h4>
          <p class="text-muted">Let's join hands together in the journey of success.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Design First Approach</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Tailor Made Solutions</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Cross Domain Experience</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Active Resource Pool for Backups</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Quick In house action</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Result Oriented Delivery Model</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Dedicated Engineering Manager</li>
          </ul>
          <a href="/page-contact-two" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">We Provide IT & Business Solutions</h4>
          <p class="text-muted para-desc mx-auto mb-0">We can help you to answer all your problems through technology.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="monitor" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Innovative Thinking</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Technology</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="eye" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Software Development</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Mobile & Web</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="aperture" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Branding and Design</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="bold" class="fea icon-ex-md text-primary"></i-feather>>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Business Idea</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="feather" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Content Strategy</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">User Experience</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="bar-chart" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Data Analytics</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="git-merge" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Artificial Intelligence</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Infrastrucutre & Monitoring</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded-pill shadow">
          <div class="icon text-center rounded-circle me-3">
            <i-feather name="Search" class="fea icon-ex-md text-primary"></i-feather>
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Cybersecurity</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-12 mt-4 pt-2 text-center">
        <a href="/page-services" class="btn btn-primary">See More <i class="uil uil-angle-right"></i></a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--end container-->
</section>
<!--end section-->
<!-- How It Work End -->

<!-- Testi Start -->
<section class="section pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h6>We believe in building each other and hence</h6>
          <h4 class="title mb-4">Work with some amazing partners</h4>
          <p class="text-muted para-desc mx-auto mb-0">Let's work together with our community and network of consultants
            to find the right solution at the right time</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <h4 class="text-muted">AGNL</h4>
                <p class="text-muted mt-4">"Aventuri was so efficient in automating the book keeping infrastructure of our financial institution thereby helping to increase our productivity"</p>
                <h6 class="text-primary">- Gopi</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <h4 class="text-muted">PointUp</h4>
                <p class="text-muted mt-4">"Aventuri's retail solutions helped to improve our business with better conversion rates. With this, we are increasing our contribution to the market share"</p>
                <h6 class="text-primary">- Lam</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->


<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Pricing End -->

<!-- FAQ n Contact Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0">How does <span class="text-primary">Aventuri</span> work ?</h5>
            <p class="answer text-muted mb-0">Aventuri started as a product development company for it's inhouse products in Retail and Finance Domains
              We further expanded our capability to cater the needs of multiple partners by providing consulting and
              outsourcing through various channels with our India Team dataEquinox</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> What is our work culture ?</h5>
            <p class="answer text-muted mb-0">Our company believes in Flat and Flexible Work Culture. We believe in a
              result oriented delivery model with a perfect balance between work and life for it's employees. We are a
              fun company. We provide flexible options for the employee to work at flex times and even on weekends to
              cope up for absence to meet delivery</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> How do we ensure continuity of an engagement?</h5>
            <p class="answer text-muted mb-0">We proactively shadow all active engagements with our inhouse developers
              to ensure continuity of the engagement in any unfortunate event in the new normal. This has helped us to
              be in the frontlines of delivery trying to avoid slippage any with Care.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex">
          <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
          <div class="flex-1">
            <h5 class="mt-0"> Is your<span class="text-primary"> IP</span> safe with Aventuri?</h5>
            <p class="answer text-muted mb-0">We guarantee that all the original code created for our customer remains
              their intellectual property and Aventuri will not have any claims whatsover. Your trust is most
              valuable to us and we will go to any extent to safeguard your property.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Have Question ? Get in touch!</h4>
          <p class="text-muted para-desc mx-auto">Do you have some problems for us to solve?<span
              class="text-primary fw-bold">Aventuri</span>
            is here for you</p>
          <a href="page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contact us</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="#" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->