import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-services',
  templateUrl: './page-services.component.html',
  styleUrls: ['./page-services.component.css']
})

/**
 * Page Service Component
 */
export class PageServicesComponent implements OnInit {

  constructor() { }

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-edit-alt h1 text-primary",
      title: "Design & Development",
      description: "We cater all your Enterprise Application needs whether it is small or Big."
    },
    {
      icon: "uil uil-airplay h1 text-primary",
      title: "Consulting & Outsourcing",
      description: "We provide outsourcing solutions at affordable rates to ease of the burden at your back."
    },
    {
      icon: "uil uil-vector-square h1 text-primary",
      title: "Management & Marketing",
      description: "We also provide Management and Marketing Ideas and Startegies required to make your Product a success."
    },
    {
      icon: "uil uil-file-search-alt h1 text-primary",
      title: "Stratagy & Research",
      description: "We have our own Tech Focused Market Research Team to find out the ideal combination of resources to pave path for your success"
    },
    {
      icon: "uil uil-clock h1 text-primary",
      title: "Infrastructure Management & Monitoring",
      description: "Safe and Secure Infrastructure development, maintainence and monitoring 24*7*365 for both On-Premise and Cloud Locations."
    },
    {
      icon: "uil uil-lightbulb-alt h1 text-primary",
      title: "Design Thinking & User Experience",
      description: "Since we follow a design first developmenet approach our designers can help you give an overview of the product even before we start building it."
    }
  ];

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Das",
      designation: "Head, India Operations",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Gopi",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Kent",
      designation: "Head, Global Tech",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  ngOnInit(): void {
  }

}
