import { Component, OnInit } from '@angular/core';

interface job {
  image: string,
  message: string,
  designation: string,
  icon: string,
  name: string,
  location: string,
  list: string[],
};

@Component({
  selector: 'app-page-jobs',
  templateUrl: './page-jobs.component.html',
  styleUrls: ['./page-jobs.component.css']
})

/**
 * Page Jobs Component
 */
export class PageJobsComponent implements OnInit {

  /**
   * Jobs Data
   */
  jobsData: job[] = [
    {
      image: "assets/images/job/full.jpg",
      designation: "Network Engineer II",
      message: "Full Time",
      icon: "assets/images/logo-icon.png",
      name: "Aventuri",
      location: "Penang, Malysia",
      list: ['7+ Years Expirence', "Working Hours- 8hr", "Linux, Windows Admin, Data Replication, VMWare"]
    },
    {
      image: "assets/images/job/full.jpg",
      designation: "Network Engineer I",
      message: "Full Time",
      icon: "assets/images/logo-icon.png",
      name: "Aventuri",
      location: "Penang, Malysia",
      list: ['2+ Years Expirence', "Working Hours- 8hr", "Linux, Windows Admin, Data Replication, VMWare"]
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
