<div class="row justify-content-center">
    <div class="col-lg-2 col-md-2 col-6 text-center" *ngFor="let data of clients_logo">
        <img src="{{data.image}}" class="avatar avatar-ex-sm" alt="">
    </div>
</div>
<br><br>
<div class="row justify-content-center">
    <div class="col-lg-2 col-md-2 col-6 text-center" *ngFor="let data of clients_logo_2">
        <img src="{{data.image}}" class="avatar avatar-ex-sm" alt="">
    </div>
</div>